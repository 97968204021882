html, body {
  background-color: white!important;
}

.newContainer {
  width: 75%!important;
}


@media screen and (max-width: 420px) {
  .newContainer { width: 80%!important } 
}
