$primaryColor: rgb(35, 145, 254);
$secondaryColor: rgb(255, 202, 39);

// .heroImgDiv {
//   img {
//     width: 100%;
//     min-height: 300px;
//     height: 100%;
//   }
// }


.heroImgDiv {
  display: flex;
  justify-content: center;
  img {
    min-height: 300px;
    max-height: 650px;
  }
}

.heroTextCol {
  justify-content: center;
  flex-direction: column;
  display: flex;
}

.heroTextDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  h3 {
    font-size: 40px;
    font-weight: 600;
    color: $primaryColor;
  }

  p {
    padding: 0.4rem;
    color: black;
    font-weight: 400;
    font-size: 20px;
  }

  img {
    max-width: 200px;
  }

  small {
    display: inline;
    margin-left: 0.4rem;
    font-weight: 500;
  }
}

@media screen and (max-width: 968px) {
  .heroTextDiv {
    h3 {
      font-size: 28px;
    }

    p {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 480px) {
  .heroTextDiv {
    text-align: center;
    small {
      display: inline-block !important;
    }

    h3 {
      font-size: 30px;
      margin-top: 0.5rem;
      text-align: center;
    }

    p {
      text-align: center;
      margin-bottom: 0.5rem;
      font-size: 18.5px;
    }

    img {
      margin-left: 0 !important;
    }

    small {
      width: 100%;
    }
  }
}
