$primaryColor: rgb(35, 145, 254);
$secondaryColor: rgb(255, 202, 39);

.orderContainer {
    text-align: center;
    height: 450px;
}

.headerText {
    font-weight: 600;
    padding-top: 0.5rem;
    margin: 1rem 0;
    text-decoration: underline;
}

.orderDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: $primaryColor;
    color: white;
    height: 80px;
    border-radius: 20px;
    padding: 1rem;
    margin: 1rem 0.2rem;

}

.text{
    font-weight: 500;
    font-size: 1.1rem;
}

@media screen and (max-width: 480px) {
    .orderContainer{
        height: 610px;
    }
    .text{
        font-weight: 500;
        font-size: 1.05rem;
    } 

}
