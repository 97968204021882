.footer {
  height: 90px;
  width: '100%';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  .icons {
    padding: 0.5rem;
  }

  .icons > * {
    margin: 1.5rem;
  }

  .text {
    margin: 0.5rem 0rem;
    text-align: center;
    h5{
      font-size: 1.1rem;
    }
  }
}

@media screen and (max-width: 425px) {
  .footer{
    .text{
      h5{
        font-size: 0.85rem;
      }
    }
  }
  
}
