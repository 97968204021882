$primaryColor: rgb(35, 145, 254);
$secondaryColor: rgb(255, 202, 39);

.navbar {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.navbarHeader {
  display: flex;
  img {
    margin-top: 11px;
    height: 35px;
    width: 35px;
    margin-right: 5px;
    border-radius: 35px;
  }
  a {
    text-decoration: none;
    margin-top: 0.8rem;
    font-size: 1.3rem;
    font-weight: 600;
    color: $primaryColor !important;
  }
}

.buttonDiv {
  button {
    font-size: 1rem;
    background-color: $secondaryColor;
    border: none;
    color: white;
    border-radius: 20px;
    padding: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: 500;
  }
}

@media screen and (max-width: 400px) {
  .navbarHeader {
    img {
      margin-top: 12px;
      height: 30px;
      width: 30px;
      border-radius: 30px;
    }
    p {
      font-size: 1.2rem;
    }
  }

  .buttonDiv {
    margin-bottom: 0.2rem;
    button {
      font-size: 0.9rem;
    }
  }
}
